<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="2">
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="名称">
              <a-input v-model="searchData.name" placeholder="名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
           <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <a-form-item label="部门">
                  <a-tree-select
                    v-model="searchData.deptId"
                    :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                    :treeData="depts"
                    treeDefaultExpandAll
                    @change="handleChangeDept"
                    placeholder="部门"
                    allowClear
                  >
                  </a-tree-select>
                </a-form-item>
              </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item :labelCol="{ span: 0 }" :wrapperCol="{ span: 22, offset: 1 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button v-if="onAdd" type="primary" @click="toHandler('add')">新增</a-button>
        <a-button v-if="onEdit" type="primary" @click="toHandler('edit')">修改</a-button>
        <a-button v-if="onDelete" type="primary" @click="toHandler('del')">删除</a-button>
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="flagEnable" slot-scope="text, record">
          <a-switch v-model="record.flagEnable" @click="onChangeStatus(record)" />
        </span>
      </a-table>

      <!-- 编辑表单 -->
      <FirmStation-edit-modal ref="FirmStationEditModal" @reload="getData"></FirmStation-edit-modal>
    </div>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import FirmStationEditModal from './components/FirmStationEditModal.vue'
import { delFirmStation, listFirmStation, selectByIdFirmStation } from './api/FirmStationApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'firmStation',
  components: {
    FirmStationEditModal,
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: Object.assign({}, pageSource),
      selectedRowKeys: [],
      selectedRows: [],
      expandedKeys: [0],
      dataList: [],
      StationId: 0,
      depts:[],
      onAdd: checkPermission('firm:station:add'),
      onEdit: checkPermission('firm:station:edit'),
      onDelete: checkPermission('firm:station:delete'),
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      listFirmStation({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        sourceType: 1,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },

      getDeptList() {
      this.axios.get('/api/base/system/firm/departmentManagement/all').then((res) => {
        const data = res.body
        this.buildtree(data, this.depts, 0)
      })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            value: item.id, // value是给modal的select用的，2者属性不一样
            title: item.name,
            scopedSlots: { title: 'title' },
            children: [],
          }
          this.buildtree(list, child.children, item.id)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
    handleChangeDept(value, label, extra) {
      // if (extra.selected && extra.triggerNode.$children.length > 0) {
      //   this.$message.error('不能选择父节点 ' + extra.triggerNode.title)
      //   value.splice(
      //     value.findIndex((item) => item === extra.triggerValue),
      //     1
      //   )
      // }
    },
    // 获取左侧树
    getTreeList() {
      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        this.dataList = res.body
      })
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    // 选中筛选
    handleSelect(value) {
      let id = Number(value.toString())
      this.StationId = id
      this.getData()
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1;
      // this.page = Object.assign({}, pageSource)
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page.current = 1;
      this.page.pageSize = 10;
      // this.page = Object.assign({}, pageSource)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.FirmStationEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.FirmStationEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delFirmStation(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
      }
    },
    onChangeStatus(record) {
      let status = record.flagEnable
      this.axios
        .get(` /api/base/system/firm/statioManagement/status/isEnable/${record.id}`)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.message)
            this.getData()
          } else {
            this.getData()
          }
        }).catch(err => {
          this.getData()
        })
    },
  },
  created() {
    this.getData()
    this.getTreeList()
    this.getDeptList()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.tree-outer {
  height: 500px;
  overflow-y: auto;
}
</style>
