/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listFirmStation = params => axios({
    url: '/api/base/system/firm/statioManagement/listFirm',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addFirmStation = params => axios({
    url: '/api/base/system/firm/statioManagement/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editFirmStation = params => axios({
    url: '/api/base/system/firm/statioManagement/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delFirmStation = params => axios({
    url:'/api/base/system/firm/statioManagement/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdFirmStation = params => axios({
    url: '/api/base/system/firm/statioManagement/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
